import { createRouter, createWebHistory } from 'vue-router';

import CollectionsView from '../views/CollectionsView.vue';
import PaintingsView from '../views/PaintingsView.vue';
import DigitalPaintingsView from '../views/DigitalPaintingsView.vue';
import WatercolorsView from '../views/WatercolorsView.vue';
import AboutView from '../views/AboutView.vue';

const routes = [
  { path: '/', redirect: '/paintings' },
  { path: '/paintings', name: 'CollectionsView', component: CollectionsView },
  { path: '/paintings/:collectionSlug', name: 'PaintingsView', component: PaintingsView },
  { path: '/digitalPaintings', name: 'DigitalPaintingsView', component: DigitalPaintingsView },
  { path: '/watercolors', name: 'WatercolorsView', component: WatercolorsView },
  { path: '/about', name: 'AboutView', component: AboutView },
];

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
});

export default router;
