<template>
  <div class="pageContent">
    <h1 class="title">{{ collection.title }}</h1>
    <div class="gallery row">
      <div v-for="(painting, index) in collection.paintings" :key="painting._id" class="galleryItem col-md-6 col-sm-12 col-xs-12">
        <img class="galleryItem-img" :src="painting.pictures[0]" @click="() => onImageClick(index)"/>
        <div class="galleryItem-info">
          <p class="galleryItem-title">
            <span>{{ painting.title }}</span>
            <span v-if="painting.available"> - available</span>
            <span v-else>- <del>sold</del></span>
          </p>
          <p class="galleryItem-dimensions">{{ painting.width }}cm x {{ painting.height }}cm</p>
          <p>{{ painting.technique }}</p>
        </div>
      </div>
    </div>

    <ModalComponent ref="modalRef" :onPreviousItem="onPreviousModalItem" :onNextItem="onNextModalItem">
      <img :src="collection.paintings[currentImageIndex].pictures[0]"/>
    </ModalComponent>
  </div>
</template>

<script>
import axios from 'axios';
import { useRoute } from 'vue-router'
import ModalComponent from '../components/ModalComponent.vue';

export default {
  data() {
    return {
      collection: {},
      currentImageIndex: '',
    };
  },
  components: {
    ModalComponent,
  },
  async mounted() {
    try {
      const route = useRoute()

      if (!route.params.collectionSlug) {
        console.error('No collection slug provided');
        return;
      }

      const response = await axios.get(`/api/collections/${route.params.collectionSlug}`)
      this.collection = (response.data?.data || {});
    }
    catch (error) {
      console.error('Error fetching collection:', error);
    }
  },
  methods: {
    onImageClick(index) {
      this.currentImageIndex = index;
      this.$refs.modalRef.openModal();
    },
    onPreviousModalItem() {
      this.currentImageIndex = (this.currentImageIndex - 1 + this.collection.paintings.length) % this.collection.paintings.length;
    },
    onNextModalItem() {
      this.currentImageIndex = (this.currentImageIndex + 1) % this.collection.paintings.length;
    },
  },
};
</script>
