<template>
  <div class="menu col-md-3 col-sm-11 col-xs-12 u-centerContent">
    <ul>
      <li>
        <router-link class="link link-logo" to="/">
          <span class="part">Sérgio</span>
          <span class="part">Pimenta</span>
        </router-link>
      </li>
      <li>
        <router-link class="link" to="/paintings">
          Paintings
        </router-link>
      </li>
      <li>
        <router-link class="link" to="/digitalPaintings">
          Digital Paintings
        </router-link>
      </li>
      <li>
        <router-link class="link" to="/watercolors">
          Watercolors
        </router-link>
      </li>
      <li>
        <router-link class="link link-about" to="/about">
          About
        </router-link>
      </li>
    </ul>
  </div>
</template>

<style scoped>
</style>