<template>
  <div class="pageContent">
    <h1 class="title">Digital Paintings</h1>
    <div class="gallery row">
      <div v-for="(digitalPainting, index) in digitalPaintings" :key="digitalPainting._id" class="galleryItem col-md-6 col-sm-12 col-xs-12">
        <img class="galleryItem-img" :src="digitalPainting.pictures[0]" @click="() => onImageClick(index)"/>
        <div class="galleryItem-info">
          <p class="galleryItem-title">{{ digitalPainting.title }}</p>
        </div>
      </div>
    </div>
  </div>

  <ModalComponent ref="modalRef" :onPreviousItem="onPreviousModalItem" :onNextItem="onNextModalItem">
    <img :src="digitalPaintings[currentImageIndex].pictures[0]"/>
  </ModalComponent>
</template>

<script>
import axios from 'axios';
import ModalComponent from '../components/ModalComponent.vue';

export default {
  data() {
    return {
      digitalPaintings: [],
      currentImageIndex: '',
    };
  },
  components: {
    ModalComponent,
  },
  async mounted() {
    try {
      const response = await axios.get('/api/digitalPaintings')
      this.digitalPaintings = (response.data?.data || []);
    }
    catch (error) {
      console.error('Error fetching digital paintings:', error);
    }
  },
  methods: {
    onImageClick(index) {
      this.currentImageIndex = index;
      this.$refs.modalRef.openModal();
    },
    onPreviousModalItem() {
      this.currentImageIndex = (this.currentImageIndex - 1 + this.digitalPaintings.length) % this.digitalPaintings.length;
    },
    onNextModalItem() {
      this.currentImageIndex = (this.currentImageIndex + 1) % this.digitalPaintings.length;
    },
  },
};
</script>
