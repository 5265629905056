<template>
  <div className="aboutPage pageContent">
    <h1 className="title">Sérgio Filipe Azevedo Pimenta</h1>
    <div className="clearfix">
      <img className="aboutPage-img" src="https://sergiopimenta.s3-eu-west-1.amazonaws.com/sergiopimenta.jpg" />
      <p className="aboutPage-text">Sérgio Pimenta, b. 1991. Based in Berlin, Germany.</p>
      <p className="aboutPage-text">Sérgio is a freelance visual artist specialising in painting and drawing. He was born in Trofa, a small town in the outskirts of Porto, Portugal where he had his first solo exhibition in 2012. In the past he worked as a painting (2013-15) and drawing (2014-15) teacher at the Portuguese Rotary Club Senior University, before relocating to Berlin where he now lives. Sérgio holds a B.A. in Painting and Fine Arts from the University of Porto.</p>
      <p className="aboutPage-text aboutPage-contacts">sergio.o.pimenta@gmail.com | +351 91 472 79 49</p>
    </div>
    <div className="about-exhibitions">
      <h2>Exhibitions</h2>
      <h3 className="about-exhibitions-title">Solo</h3>
      <p>Painting/Drawing</p>
      <ul>
        <li>"Limites", 7 Mares, Berlin, 2017</li>
        <li>"1ª Etapa", Casa da Cultura da Trofa, Trofa, 2016</li>
        <li>Pub Pesca Submarina, Trofa, 2013</li>
        <li>"730 Dias", Casa da Cultura da Trofa, Trofa, 2012</li>
      </ul>
      <h3 className="about-exhibitions-title">Collective</h3>
      <ul>
        <li>"Arte&Negócios", AIP (Associação Industrial Portuguesa), Lisboa, 2017</li>
        <li>"Arte&Negócios", Porto Business School, Porto, 2017</li>
        <li>"Focus", Fellini Gallery, Berlin, 2016</li>
        <li>"8ª Exposição", Projecto Shair, Galerias Emergentes, Braga, 2015</li>
        <li>"Emerging Art Now", Mira Fórum, Porto, 2015</li>
        <li>"P3 Pintura Prática e Pensamento", Fórum da Maia, Maia, 2013</li>
        <li>Faculdade de Belas Artes da Universidade do Porto, Porto, 2013</li>
        <li>Casa da Cultura da Trofa, Trofa, 2009</li>
      </ul>
    </div>
  </div>
</template>
