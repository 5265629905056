<template>
  <div class="pageContent">
    <h1 class="title">Watercolors</h1>
    <div class="gallery row">
      <div v-for="(watercolor, index) in watercolors" :key="watercolor._id" class="galleryItem col-md-6 col-sm-12 col-xs-12">
        <img class="galleryItem-img" :src="watercolor.pictures[0]" @click="() => onImageClick(index)"/>
        <div class="galleryItem-info">
          <p class="galleryItem-title">
            <span>{{ watercolor.title }}</span>
            <span v-if="watercolor.available"> - available</span>
            <span v-else>- <del>sold</del></span>
          </p>
          <p class="galleryItem-dimensions">{{ watercolor.width }}cm x {{ watercolor.height }}cm</p>
        </div>
      </div>
    </div>

    <ModalComponent ref="modalRef" :onPreviousItem="onPreviousModalItem" :onNextItem="onNextModalItem">
      <img :src="watercolors[currentImageIndex].pictures[0]"/>
    </ModalComponent>
  </div>
</template>

<script>
import axios from 'axios';
import ModalComponent from '../components/ModalComponent.vue';

export default {
  data() {
    return {
      watercolors: [],
      currentImageIndex: '',
    };
  },
  components: {
    ModalComponent,
  },
  async mounted() {
    try {
      const response = await axios.get('/api/watercolors')
      this.watercolors = (response.data?.data || []);
    }
    catch (error) {
      console.error('Error fetching watercolors:', error);
    }
  },
  methods: {
    onImageClick(index) {
      this.currentImageIndex = index;
      this.$refs.modalRef.openModal();
    },
    onPreviousModalItem() {
      this.currentImageIndex = (this.currentImageIndex - 1 + this.watercolors.length) % this.watercolors.length;
    },
    onNextModalItem() {
      this.currentImageIndex = (this.currentImageIndex + 1) % this.watercolors.length;
    },
  },
};
</script>
