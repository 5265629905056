<template>
  <div class="pageContent">
    <h1 class="title">Collections</h1>
    <div class="gallery-collections row">
      <div v-for="collection in collections" :key="collection._id">
        <router-link
          class="galleryItem link col-md-7 col-sm-12 col-xs-12"
          :to="`/paintings/${collection.slug}`">
          <div class="galleryItem-info">
            <p class="galleryItem-title">{{ collection.title }}</p>
          </div>
          <img class="galleryItem-img" :src="collection.previewPicture" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return { collections: [] };
  },
  async mounted() {
    try {
      const response = await axios.get('/api/collections')
      this.collections = (response.data?.data || []);
    }
    catch (error) {
      console.error('Error fetching collections:', error);
    }
  }
};
</script>
