<template>
  <div v-if="isVisible">
    <div class="modal-backdrop"></div>
    <div class="modal" @click="closeModal">
      <div class="modal-dialog">
        <div className="arrow-left" @click="onPreviousItem" v-on:click.stop>
          <i className="fa fa-angle-left"></i>
        </div>
        <div className="arrow-right" @click="onNextItem" v-on:click.stop>
          <i className="fa fa-angle-right"></i>
        </div>
        <div class="modal-content">
          <div className='u-centerContent modal-body'>
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalComponent',
  data() {
    return {
      isVisible: false,
    };
  },
  props: {
    onPreviousItem: Function,
    onNextItem: Function,
  },
  methods: {
    openModal() {
      this.isVisible = true;
      document.addEventListener('keydown', this.handleKeydown);
    },
    closeModal() {
      this.isVisible = false;
      document.removeEventListener('keydown', this.handleKeydown);
    },
    handleKeydown(event) {
      if (event.key === 'Escape' || event.key === 'Esc') {
        this.closeModal();
      }
      else if (event.key === 'ArrowLeft') {
        this.onPreviousItem();
      }
      else if (event.key === 'ArrowRight') {
        this.onNextItem();
      }
    },
  },
}
</script>
