import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

// Import Bootstrap CSS file.
import 'bootstrap/dist/css/bootstrap.css';

import './styles/main.scss';


createApp(App)
  .use(router)
  .mount('#app');
