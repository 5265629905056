<template>
  <div class="mainContainer">
    <MenuComponent />
    <div class="content col-md-9 col-sm-11 col-xs-12 u-centerContent">
      <router-view />
    </div>
  </div>
</template>

<script>
import MenuComponent from './components/MenuComponent.vue';

export default {
  name: 'App',
  components: {
    MenuComponent,
  }
};
</script>

<style>
  @import "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.3.15/slick.css";
  @import "https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css";
  @import "https://fonts.googleapis.com/css?family=Khand:400,600";
</style>